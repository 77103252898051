import { useForm } from '@formspree/react';
import React from 'react';
import styled from 'styled-components';

// Container for the whole section
const Container = styled.div`
  background-color: #78bc44; // The specified green background color
  padding: 40px 80px; // Adjusted for padding on all sides
  display: flex;
  align-items: center; // Vertically center everything
  justify-content: space-around; // Evenly space out title and form
  flex-wrap: wrap; // Wrap elements on smaller screens
`;

// Title container for better control over the title and subtitle
const TitleContainer = styled.div`
  max-width: 600px; // Max width for the title container
`;

const Title = styled.h2`
  color: white;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  @media (max-width: 917px) {
    text-align: center; // Center the items when stacked
  }
`;

const Subtitle = styled.p`
  color: white;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  @media (max-width: 917px) {
    text-align: center; // Center the items when stacked
  }
`;

// Form component for email, name and submit button
const Form = styled.form`
  display: flex;
  flex-direction: row; // Align form inputs in a row
  gap: 15px; // Spacing between form elements

  @media (max-width: 917px) {
    flex-direction: column; // Stack form elements vertically on mobile
    align-items: center; // Center the items when stacked
    width: 100%; // Full width for smaller screens
  }
`;

const Input = styled.input`
  padding: 15px;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  flex-grow: 1; // Inputs grow to fill available space
`;

const Button = styled.button`
  padding: 15px 25px;
  border-radius: 5px;
  border: none;
  background-color: darkgreen; // A darker green for the button
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #005700; // Even darker green on hover
  }
`;


const ConfirmationMessage = styled.p`
  width: 100%;
  text-align: center;
  color: white;
  background-color: #78bc44;
  padding: 20px;
  font-size: 1rem;
  margin-top: 20px; // Adds a bit of space above the message
`;
export default function CallBack() {
  const [state, handleSubmit] = useForm("meqyvnrk");

  if (state.succeeded) {
    return (
      <Container id="callBackSection">
        <ConfirmationMessage>Thanks for your submission!</ConfirmationMessage>
      </Container>
    );
  }

  return (
      <div id="callBackSection">
    <Container>
      <TitleContainer>
        <Title>Request a Call Back</Title>
        <Subtitle>
          Let us know what you're looking for in our product.
          We'll take a look and see if this could be the start of something beautiful.
          Please get in touch and let's schedule time to talk!
        </Subtitle>
      </TitleContainer>


      <Form onSubmit={handleSubmit}>
        <Input type="text" name="What would you like?" placeholder="What would you like?" />
        <Input type="email" name="email" placeholder="Your Email" />
        <Input type="text" name="name" placeholder="Your Name" />
        <Button type="submit" disabled={state.submitting}>SUBMIT</Button>
      </Form>
    </Container>
      </div>
  );
}