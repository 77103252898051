import React from 'react';
import styled from 'styled-components';


const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  padding: 40px; // Increased padding
  background-color: #f0f0f0;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Content = styled.div`
  flex: 3; // Allocate more space to content than the image
  padding: 0 40px; // Increased right padding for more space

  @media (max-width: 768px) {
    padding: 0 20px; // Adjust padding for mobile screens
    margin-bottom: 20px;
  }
`;

const ImageContainer = styled.div`
  flex: 2; // Allows the image container to take up 2 parts out of 5 (since Content is flex: 3)
  padding: 10px; // Padding around the image

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
  }
`;

const StyledImage = styled.img`
  width: 100%; // Ensure the image is responsive
  height: auto; // Maintain aspect ratio
  object-fit: cover; // Cover the containing element with the image without stretching
`;






const Title = styled.h2`
  color: #333;
  margin-bottom: 0.5em; // Add a little bottom margin to the title

  @media (max-width: 768px) {
    text-align: center; // Center the title on mobile devices
    font-size: 1.5em;
  }
`;


const Text = styled.p`
  font-size:1em;
  @media (max-width: 768px) {
    font-size: 1.2em; // Increase paragraph text size for readability on mobile
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin-bottom: 10px; // Spacing between list items
  min-width:20px;
  &::before {
    content: '✔'; // Custom bullet point
    color: green; // Bullet point color
    margin-right: 5px; // Space between bullet point and text
  }
`;



const media = {
    mobile: '@media(max-width: 768px)'
};

const ResponsiveImage = styled(StyledImage)`
    ${media.mobile} {
      max-width: 100%; // Full width on smaller screens
      height: auto; // Adjust height automatically
    }
    @media (max-width: 768px) {
      width: 100%; // Full width on smaller screens
      height: auto; // Adjust height automatically
    }
  `;


export default function MainIndustries({ videoLoading = false }) {
    return (
        <Section>
            <Content>
                <Title>Our main Industries</Title>
                <Text>Our fittings are versatile and offer many benefits & solutions to many sectors. Most benefits come to any application where a secure and speedy installation is required and follow up maintenance is required.</Text>
                <List>
                    <ListItem>Water Meter Applications</ListItem>
                    <ListItem>Water Purification Applications</ListItem>
                    <ListItem>Pressure Pump Applications</ListItem>
                    <ListItem>A Broad-Range Of General Plumbing Applications</ListItem>
                </List>
            </Content>
            <ImageContainer>
                <ResponsiveImage
                    src="https://res.cloudinary.com/dhz5msoml/image/upload/v1718729920/main_industries_hsnudv.png"
                    alt="Water Meter Applications"
                />
            </ImageContainer>
        </Section>
    );
}
