import React from 'react';
import styled from 'styled-components';



const mobileBreakpoint = '768px'; 

// Main section container
const MissionSection = styled.section`
  background-color: #f4f4f4; // Example background color
`;

// Upper row container for the image and mission text
const UpperRow = styled.div`
  display: flex;
  
  align-items: stretch; // Stretch children to fill the container height
  justify-content: space-between; // Align items side by side
  margin-bottom: 2rem; // Space between this row and the next

  @media (max-width: ${mobileBreakpoint}) {
    flex-direction: column; // Stack vertically on mobile
  }
`;


// Content container with text
const MissionContent = styled.div`
  flex: 1; // Takes up the remaining space after the image
  padding: 70px;

  background-color: #78bc44;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  
  
  @media (max-width: 1099px) {
    padding: 50px; // Reduce padding on mobile for more space
  }

  @media (max-width: ${mobileBreakpoint}) {
    padding: 50px; // Reduce padding on mobile for more space
  }


  
`;



// Header title
const MissionTitle = styled.h1`
color: white;

  font-size: 2rem; // Adjust font size as needed
  margin-bottom: 1rem; // Space between title and text
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

// Main mission statement text
const MissionText = styled.p`
  color: white;
  
  font-size: 1rem; // Adjust font size as needed
  margin-bottom: 2rem; // Space between mission statement and points

  @media (max-width: 768px) {
    font-size: 0.7rem;

  }
`;

// Image container
const StyledMissionImage = styled.img`
  width: 50%; // Adjust based on your design
  height: auto; // Ensure the height scales proportionally
  object-position: center; // Align the image to the left center
  object-fit: cover; // Cover the area without stretching the image


  @media (max-width: ${mobileBreakpoint}) {
    width: 100%; // Full width on mobile
    margin-bottom: 1rem; // Space between image and text
  }
  
`;

// Container for the points, below the image and text
const MissionPoints = styled.div`
  display: flex;
  margin-top: -2rem; // Space from the upper row

  @media (max-width: ${mobileBreakpoint}) {
    flex-direction: column; // Stack vertically on mobile
  }
`;

// Individual point container
const Point = styled.div`
  flex: 1; 
  background: ${(props) => props.dark ? '#333333' : '#e9ecef'}; 
  padding: 20px;
  color: ${(props) => props.dark ? 'white' : '#555'}; // White text if 'dark' prop is true, else dark text

  @media (max-width: ${mobileBreakpoint}) {
    border-left: none; // Remove border for mobile
    &:not(:last-child) {
      margin-bottom: 1rem; // Space between points
    }
  }

`;
// Point title
const PointTitle = styled.h2`
  font-size: 1.2rem; // Adjust as needed
  color: ${(props) => props.dark ? 'white' : '#000'}; // White text if 'dark' prop is true, else black text
  margin-bottom: 0.5rem; // Space between title and description
`;

// Point description
const PointDescription = styled.p`
  font-size: 0.7rem; // Adjust as needed
  color: ${(props) => props.dark ? 'white' : '#555'}; // White text if 'dark' prop is true, else gray text
`;


export default function OurMission() {
    return (
        <MissionSection>
            <UpperRow>
                <StyledMissionImage
                    src="https://res.cloudinary.com/dhz5msoml/image/upload/v1718729746/missionimage_fpuksd.png"
                    alt="Mission Image"
                />
                <MissionContent>
                    <MissionTitle>What Is Our Mission?</MissionTitle>
                    <MissionText>
                        Our mission is to significantly reduce water loss and wastage by revolutionizing the water meter installation process. By offering versatile, easy-to-install, and secure products, we aim to minimize the risks associated with poor installation, leaks, and theft, ensuring a more sustainable and efficient use of water resources.
                    </MissionText>
                </MissionContent>
            </UpperRow>
            <MissionPoints>
            <Point dark>
                    <PointTitle dark>01. Introducing a New Era</PointTitle>
                    <PointDescription dark>
                        We're bringing our groundbreaking products directly to you, with a focus on understanding and addressing your specific needs. This is about more than just products; it's about starting a movement towards smarter, more efficient water use.
                    </PointDescription>
                </Point>
                <Point>
                    <PointTitle>02. Empowering Your Choice</PointTitle>
                    <PointDescription>
                        In this phase, it's all about putting our innovative solutions into your hands. We want our products to be a part of your everyday life, transforming the way you think about and manage water in your home or business.
                    </PointDescription>
                </Point>
                <Point dark>
                    <PointTitle dark>03. Transforming Together</PointTitle>
                    <PointDescription dark>
                        The final step is where you witness the real change. By choosing our products, you're not just preventing water wastage; you're joining a larger movement. Together, we'll bring about a significant shift in sustainable water use, making a positive impact on the environment and our future.
                    </PointDescription>
                </Point>
            </MissionPoints>
        </MissionSection>
    );
}
