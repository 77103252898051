import React from 'react';
import styled from 'styled-components';

const ReviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap; // Allow flex items to wrap onto multiple lines
  justify-content: space-around;
  align-items: flex-start; // Changed from center to flex-start for better alignment when wrapping
  color: white;
  background-color: #302c2c;
  padding: 20px;
  gap: 20px; // Adds space between flex items when they wrap
`;

const Review = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  flex-basis: 300px; // Set a base width for each review box
  flex-grow: 1; // Allow boxes to grow if there is extra space
`;


const ReviewerImage = styled.img`
  height: 40px; // Adjust the height as necessary
  margin-bottom: 10px;

  @media (max-width: 400px) {
    height: 30px; // Adjust the height as necessary
  }
  `;

const Heading = styled.h1`
  background-color: #302c2c;
  text-align: center;
  color: white;
  width: 100%;
  font-weight:100;
  padding: 10px 0;
`;

export default function RelatedProducts() {
  return (
    <>

      <ReviewContainer>
        <Heading>Related Products</Heading>
        <Review>
          <a href="https://unitwist.com">
            <ReviewerImage src={"https://res.cloudinary.com/dhz5msoml/image/upload/c_scale,w_1000/v1718730484/UNIFLOW_oue4pe.png"} alt="Reviewer" />
          </a>
        </Review>
        <Review>
          <a href="https://unitwist.com">
            <ReviewerImage src="https://res.cloudinary.com/dhz5msoml/image/upload/c_scale,w_1000/v1718730479/UNITWIST_kbu3qp.png" alt="Reviewer" />
          </a>
        </Review>

        <Review>
          <a href="https://unitwist.com">
            <ReviewerImage src="https://res.cloudinary.com/dhz5msoml/image/upload/c_scale,w_1000/v1718730483/CLICKCLOSE_ms4n32.png" alt="Reviewer" />
          </a>
        </Review>

        <Review>
          <a href="https://unitwist.com">
            <ReviewerImage src="https://res.cloudinary.com/dhz5msoml/image/upload/c_scale,w_1000/v1718730484/EASYBAT_obchez.png" alt="Reviewer" />
          </a>
        </Review>

        <Review>
          <a href="https://unitwist.com">
            <ReviewerImage src="https://res.cloudinary.com/dhz5msoml/image/upload/c_scale,w_1000/v1718730479/EASYTAIL_1_kphqff.png" alt="Reviewer" />
          </a>
        </Review>
        {/* Add more <Review> components as needed */}
      </ReviewContainer>
    </>

  );
}
