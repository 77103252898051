import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar';
import HeroSection from './components/HeroSection';
import BadgeBanner from './components/BadgeBanner';
import OurMission from './components/OurMission';
import Products from './components/Products';
import WorkWithUsBanner from './components/WorkWithUsBanner';
import MainIndustries from './components/MainIndustries';
import RelatedProducts from './components/RelatedProducts';
import CallBack from './components/CallBack';

function App() {
  return (
    <>
      <Navbar />
      <HeroSection />
      <BadgeBanner />
      <OurMission />
      <Products />
      <WorkWithUsBanner />
      <MainIndustries />
      <RelatedProducts />
      <CallBack />
    </>

  );
}

export default App;
