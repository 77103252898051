import React from 'react';
import styled from 'styled-components';



const Banner = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff; // Set your desired background color
  padding: 20px;
  text-align: center;

  // Media query to stack items on smaller screens
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const BadgeItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
`;

const StyledBannerImage = styled.img`
  width: 200px; // Adjust as needed
  height: auto;
  margin-bottom: 10px;

  @media(max-width:1190px){
    width:150px;
  }
`;

const BadgeText = styled.p`
  color: #333; // Set your desired text color
  font-size: 14px; // Adjust as needed
  width: 200px; // Adjust as needed for your layout
`;

const MiddleText = styled.div`
  font-size: 16px; // Adjust font size to match the reference
  color: #333; // Choose a professional color, darker shades tend to look more professional
  max-width: 600px; // Adjust the width to contain your text nicely
  margin: 0 auto; // Center the text box
  padding: 40px 20px; // Add some padding to create space around the text
  line-height: 1.6; // This affects the spacing between lines
  font-weight: normal; // Adjust the font weight as needed
  text-align:center;

  // Add media queries for responsive design as needed
  @media (max-width: 768px) {
    padding: 20px; // Reduce padding on smaller screens
    font-size: 14px; // Optionally reduce the font size on smaller screens
  }
`;

export default function BadgeBanner() {
  
    return (
  <>

  
<MiddleText>
  With over 20 years of creating market leading pipe fittings & related products, we know a thing or two about what it takes to make installations easier, faster and less prone to leaks, affordably.
</MiddleText>

  <Banner>
            <BadgeItem>
                <StyledBannerImage
                    src="https://res.cloudinary.com/dhz5msoml/image/upload/v1718729787/25_year_ab1b3s.png"
                    alt="Quality Guarantee"
                />
                <BadgeText>All our fittings come with a 25 year guarantee against manufacturing defects.</BadgeText>
            </BadgeItem>
            <BadgeItem>
                <StyledBannerImage
                    src="https://res.cloudinary.com/dhz5msoml/image/upload/v1718729786/South_African_zkdclg.png"
                    alt="Local Manufacture"
                />
                <BadgeText>All fittings are proudly manufactured in South Africa, in KZN.</BadgeText>
            </BadgeItem>
            <BadgeItem>
                <StyledBannerImage
                    src="https://res.cloudinary.com/dhz5msoml/image/upload/v1718729786/%C3%87ost_Effective_wunhlw.png"
                    alt="Versatility"
                />
                <BadgeText>Our products are designed to be both affordable and extremely versatile.</BadgeText>
            </BadgeItem>
        </Banner>
        </>
    );
}
