import React from 'react';
import styled from 'styled-components';

const Banner = styled.div`
  background-color: #367C2B; // Dark green background
  color: white;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between; // Maintain space between the two columns

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    align-items: stretch; // Align items to stretch to the container width
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1; // Allows text container to take necessary space

  @media (max-width: 768px) {
    align-items: flex-start; // Align text to the left on mobile
    order: 1; // Text appears first on mobile
  }
`;

const BannerText = styled.div`
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    font-size: 16px; // Slightly smaller font size for mobile
  }
`;

const BannerSubText = styled.div`
  font-size: 14px;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 12px; // Smaller font size for mobile
  }
`;

const ButtonContainer = styled.div`
  // Flex container for the button to position it on the right
  display: flex;
  justify-content: flex-end; // Align button to the right
  flex-grow: 0; // Do not allow button container to grow

  @media (max-width: 768px) {
    order: 2; // Button appears second on mobile
    width: 100%; // Full width to align center
    justify-content: center; // Center button on mobile
  }
`;

const Button = styled.button`
  background-color: #448C3D;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;

  &:hover {
    background-color: #3A7C35;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
    margin-top: 10px;
  }
`;

export default function WorkWithUsBanner() {
  const scrollToCallBack = () => {
    const section = document.getElementById('callBackSection');
    section.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Banner>
      <TextContainer>
        <BannerText>Want to work with us?</BannerText>
        <BannerSubText>If you have any questions - please contact us via the Contact Form.</BannerSubText>
      </TextContainer>
      <ButtonContainer>
        <Button onClick={scrollToCallBack}>GET IN TOUCH</Button>
      </ButtonContainer>
    </Banner>
  );
}
