import React from "react";
import logoblack from '../logoet.png'
import logoeee from '../eee.png'
import styled from "styled-components";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  background: linear-gradient(to right, black, green 50%);

  position: fixed;
  top: 0;
  z-index: 10;
  padding:10px 20px;
`;

const Logo = styled.img`
  height: 40px;
  @media(max-width: 768px) {
    height: 20px; 
  }
`;

const Logoo = styled.img`
  height: 40px;
  margin-left:-135px;
  @media(max-width: 768px) {
    height: 20px; 
    margin-left:-50px;
  }
`;


const CenterText = styled.div`
  flex-grow: 1; 
  text-align: end; 
  font-size: 1em;
  margin-right:50px;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
  text-decoration: none;
  color: white;

  &:hover {
    opacity: 1; 
  }

  @media(max-width: 768px) {
    font-size: 0.7em; 
  }
`;



export default function Navbar() {
    return (
        <NavbarContainer>
            <Logo src={logoblack} />
            <Logoo src={logoeee} />

            <CenterText>
                <a href="tel:+27834586521" style={{ color: 'white', textDecoration: 'none' }}>
                    <FontAwesomeIcon icon={faPhone} />
                    +83 458 6521
                </a>
            </CenterText>

        </NavbarContainer>
    )
}