import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock as farClock,
  faStar as farStar,
} from "@fortawesome/free-regular-svg-icons";

const mobileBreakpoint = "768px"; // This should be a string to be used in the template literal below

const ProductSection = styled.section`
  text-align: center;
  padding: 50px 0; // Add padding as needed
  background-color: #fff; // Or any color that fits your design
`;

const Title = styled.h2`
  color: #000; // Adjust the color to fit your design
  margin-bottom: 20px; // Gives space below the title
`;

const Subtitle = styled.p`
  color: #666; // Adjust the color to fit your design
  margin-bottom: 40px; // Gives space below the subtitle
`;

const FeaturesWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: ${mobileBreakpoint}) {
    flex-direction: column;
    align-items: center;
  }
`;

const FeatureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;

  @media (max-width: ${mobileBreakpoint}) {
    justify-content: flex-start;
  }
`;

const FeatureText = styled.div`
  color: #000;
  font-size: 1.2em;
  margin-left: 10px;

  @media (max-width: ${mobileBreakpoint}) {
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const Feature = styled.div`
  display: inline-block; // Display features side by side
  margin: 0 20px; // Add space between features
  text-align: left;

  &:before {
    content: "";
    display: block;
    margin-bottom: 10px;
    height: 60px; // Height for your icons
    width: 60px; // Width for your icons
    background-image: url("path-to-your-icon.png"); // Add path to your icon
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

const ProductImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
`;

const ProductImageWrapper = styled.div`
  text-align: center;
  margin: 10px;
`;

const ProductImage = styled.img`
  height: 250px; // Fixed height for all images
  width: 250px; // Fixed width for all images
  object-fit: contain; // Ensures the whole image fits within the dimensions without cropping

  @media (max-width: 1720px) {
    height: 200px; // Fixed height for all images
    width: 200px;
  }

  @media (max-width: 1420px) {
    height: 150px; // Fixed height for all images
    width: 150px;
  }
`;

const ImageHeading = styled.h3`
  color: #333;
  font-size: 1em; // Adjust font size as needed
`;

export default function Products() {
  const imageDetails = [
    {
      src: "https://res.cloudinary.com/dhz5msoml/image/upload/v1718729835/Layer_1_copy_wwgqur.png",
      product_variation_one: '1"x22mm',
      product_variation_two: '1"x20mm',
    },
    {
      src: "https://res.cloudinary.com/dhz5msoml/image/upload/v1718729836/Layer_1_copy_3_gpuyiq.png",
      product_variation_one: '3/4"x22mm',
      product_variation_two: '3/4"x20mm',
    },
    {
      src: "https://res.cloudinary.com/dhz5msoml/image/upload/v1718729835/Layer_1_copy_5_whcqyx.png",
      product_variation_one: '3/4"x15mm',
      product_variation_two: '3/4"x16mm',
    },
    {
      src: "https://res.cloudinary.com/dhz5msoml/image/upload/v1718729837/Layer_1_copy_2_s95914.png",
      product_variation_one: '1"x22mm',
      product_variation_two: '1"x20mm',
    },
    {
      src: "https://res.cloudinary.com/dhz5msoml/image/upload/v1718729836/Layer_1_copy_4_bxzkfl.png",
      product_variation_one: '3/4"x22mm',
      product_variation_two: '3/4"x20mm',
    },
    {
      src: "https://res.cloudinary.com/dhz5msoml/image/upload/v1718729838/Layer_1_yhdxme.png",
      product_variation_one: '3/4"x15mm',
      product_variation_two: '3/4"x16mm',
    },
  ];
  return (
    <ProductSection>
      <Title>The Products</Title>
      <Subtitle>
        Guaranteed to change the life of anyone who is familiar with the
        applications in which these products are used.
      </Subtitle>
      <FeaturesWrapper>
        <FeatureContainer>
          <Icon color="red" size="2x" icon={farClock} />
          <FeatureText>
            500-1000%
            <br />
            Faster Installation Time
          </FeatureText>
        </FeatureContainer>
        <FeatureContainer>
          <Icon color="red" size="2x" icon={farStar} />
          <FeatureText>
            6<br />
            Configurations Available
          </FeatureText>
        </FeatureContainer>
      </FeaturesWrapper>
      <ProductImages>
        {imageDetails.map((image) => (
          <ProductImageWrapper key={image.src}>
            <ProductImage src={image.src} alt={image.alt} />

            <ImageHeading>{image.product_variation_one}</ImageHeading>
            <ImageHeading>{image.product_variation_two}</ImageHeading>
          </ProductImageWrapper>
        ))}
      </ProductImages>
    </ProductSection>
  );
}
