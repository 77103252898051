import React from 'react'
import { Video } from 'cloudinary-react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction:column;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    height: 600px; 
  }
`;
const TextColumn = styled.div`
    text-align:center;
    padding-top:125px;
    padding-left:100px;
    padding-right:100px;
    padding-bottom:30px;
  @media (max-width: 768px) {
    padding-top:105px;
    padding-left:0px;
    padding-right:0px;
    padding-bottom:0px;
  }
`;
const VideoColumn = styled.div`
  height: 100%; // Ensure the video container has the same height as the text column

`;

const StyledVideo = styled(Video)`
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
  object-fit: contain;
  z-index: 1;
`;

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const OverlayContent = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: left;
  color: white;
  z-index: 2;
  padding: 20px;
  padding-left: 80px;
  width: 100%; 
  box-sizing: border-box; 

  @media (max-width: 768px) {
    padding-left: 20px;
    text-align: center;

  }
`;

const Heading = styled.div`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1.0rem;
  }
`;

const Subheading = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const DescText = styled.div`
  font-size: 1.2rem;
  line-height: 1.6; 
  font-weight: normal; 
  margin-bottom: 1.5rem; 

  @media (max-width: 768px) {
    font-size: 0.7rem;
    text-align: center;
    padding-left:20px;
    padding-right:20px;

  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border-radius:10px;
  margin-right: 10px; 
  background-color: #008000;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  display: inline-block;

  &:hover {
    background-color: #005700; 
  }

  @media (max-width: 768px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.8rem;
  }
`;

const DownloadIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  font-size: 2em;
  color: white;
  z-index: 5;
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    color: #ccc; 
  }

  @media (max-width: 1116px) {
  bottom: 100px;
  width:15px;
  }
`;

const GreenText = styled.span`
  color: #78bc44;
`;
export default function HeroSection() {
  const [videoLoading, setVideoLoading] = React.useState(true);
  const [videoPublicId, setVideoPublicId] = React.useState("EASYTAIL_INTRODUCTION_VIDEO_o4zevz.mp4");
  return (
    <SectionWrapper>

      <TextColumn>

        <Heading>LESS FITTINGS. <GreenText>
          FASTER INSTALLS.</GreenText></Heading>
        <Subheading>Saving You Time & Money</Subheading>

        <DescText>
          Our product line is designed for the water meter market and features innovative, tool-free fittings with a slip nut for easy installation and removal, reducing leak risks and the need for multiple fittings. Made from durable plastic, these fittings are less prone to theft and are versatile enough for use in water purification and pump installations.
        </DescText>
      </TextColumn>
      <VideoColumn>
        <StyledImage
          src="https://res.cloudinary.com/dfvwdmqzp/video/upload/v1713959016/EASYTAIL_INTRODUCTION_VIDEO_ajcqkq.mp4"
          alt="Video Placeholder"
          style={{ display: videoLoading ? 'block' : 'none' }}
        />
        <StyledVideo
          cloudName="dhz5msoml"
          publicId={videoPublicId.replace('.mp4', '')}
          autoPlay
          controls
          loop
          playsInline
          style={{ width: '100%', height: '100%' }}
          onLoadedData={() => setVideoLoading(false)}
        />
      </VideoColumn>

    </SectionWrapper>
  )
}
